import React from 'react'
import "./Loader.css"
function Loader() {
  return (
<div class="loader-container">
  <div class="loader"></div>
</div>

  )
}

export default Loader